.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #121430;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-body {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  color: white;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.App-footer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-left: 50px;
}

.down-arrow {
  color: black;
  position: absolute;
  top: 80vh;
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}

.rogueLightBlue {
  color: #2cc0c0;
}

.rogueDarkBlue {
  color: #121430;
}

.rogueGreen {
  color: #a5df3a;
}

.roguePurple {
  color: #b22890;
}

a {
  color: #b22890;
}

.logo {
  height: 5vh;
  width: 5vh;
}